import Header from "./Header";
import Footer from "./Footer";
import StepForm from "./StepForm";
import { useState } from "react";
import ServicePageLanding from "./ServicePageLanding";
import axios from "axios";
import SplitText from "./SplitText";
import { NavLink } from "react-router-dom";

function ServicePageLayout(props){
    let input = {};
    props.steps.map((item)=> item.questions.map((question) => input = {...input, [question.name] : ""}))

    const [activeFormStep, setActiveFormStep] = useState(0);
    const [formInput, setformInput] = useState(input)
    const submitForm = () => {
        // TODO 
        // Validation
        let content =  "**__New Contact Form Submission__**\n**Page**:";
        content += props.name
        content += "\n\n```";
        let keys = Object.keys(formInput)
        keys.forEach((key, index) => {    
            content += `${key}: ${formInput[key]}\n`
        });
        axios.post("https://discord.com/api/webhooks/994391755753529445/XWgno6TlfrjSt3C51vk_tlvzg5ALQMVIN8y54ASAS5UUTs0bHKtB08iqoJDVY6ZM4GbX", {content: content + "```"})

        setActiveFormStep(-1)        
      }
    return (
    <div className='flex flex-col h-full overflow-hidden'>
        <Header />

        <div className="flex flex-col flex-1 overflow-scroll" >
        { activeFormStep === 0 
            ? <ServicePageLanding title={props.title} subtitle={props.subtitle} action={()=>{setActiveFormStep(1)}}/> 
            : null
        }
        { props.steps.map((step, index)=>{
            return (
                <StepForm key={index} active={activeFormStep} number={index+1} {...step} 
                    onchange={setformInput} answers={formInput}
                    action={(e) => {
                    e.target.attributes['back-button']
                    ? setActiveFormStep(activeFormStep-1)
                    : activeFormStep === props.steps.length 
                        ? submitForm() 
                        : setActiveFormStep(activeFormStep+1)
                    }} />  
            )
            })
        } 
        { activeFormStep === -1
            ? <div className="p-10 flex flex-col flex-1 text-center justify-center">
                <h1 className="text-3xl md:text-4xl font-black mb-3 "><SplitText text="Thanks for your submission!"/></h1>
                <h2 className="md:text-2xl max-w-screen-sm mx-auto"><SplitText text="We will be in contact soon"/></h2>
                <div className="mt-10">
                    <NavLink to="/" className="btn-primary" >explore our other services</NavLink>
                </div>
                </div>
            : null
        }
        </div>
        <Footer />
    </div>
    );
}

export default ServicePageLayout;