import { Link, NavLink } from 'react-router-dom';
import logo_black from '../images/logo_black.svg';

function Header(props) {
  return (
    <div className={`p-4  md:py-3 md:px-8  flex justify-between z-[1] w-screen items-center ${props.classes ? props.classes : ''}`}>
      <NavLink to="/" className=''><img src={logo_black} alt="" width={120}/></NavLink>
      <NavLink to="/contact" className={({isActive}) => isActive ? "btn-primary active": "btn-primary"}>contact us</NavLink>
    </div>
  );
}

export default Header;
