import gsap, { ScrollToPlugin } from "gsap/all";
import { useLayoutEffect, useRef } from "react";
import SplitText from './SplitText'
gsap.registerPlugin(ScrollToPlugin);

function ServicePageLanding(props){
    let tl = gsap.timeline();
    let el = useRef();
    let q = gsap.utils.selector(el);
    
    useLayoutEffect(()=>{
      tl.from(
        q('.welcome > *'), {
          y: 1000,
          opacity:0,
          stagger: 0.1,
        })

    }, [])

    let startForm = () => {
      tl.to(
        q('.welcome > *'), {
          y: -1000,
          opacity:0,
          stagger: 0.1,
          onComplete: props.action
      })
    }
  
    return (
      <div className="p-10 flex flex-col flex-1 text-center justify-center welcome" ref={el}>
        <h1 className="text-3xl md:text-4xl font-black mb-10 "><SplitText text={props.title}/></h1>
        <h2 className="md:text-2xl max-w-screen-sm mx-auto"><SplitText text={props.subtitle}/></h2>
        <div className="mt-10">
            <button className="btn-primary" onClick={startForm}>Please follow the journey and allow us to explore your needs.</button>
        </div>
      </div>
    )
}
export default ServicePageLanding;