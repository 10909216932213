import { NavLink } from 'react-router-dom';


function Footer(props) {
  
  return (
    <div className={`p-2 md:py-3 md:px-8 md:flex justify-between w-screen items-center ${props.classes}`}>
      <div className="flex text-gray-400 justify-between md:gap-1 text-sm tracking-wide">
        <NavLink className={({isActive}) => isActive ? "text-[#179DAD] font-bold" : ""} to='/software-development'>custom&nbsp;software&nbsp;solutions</NavLink>&nbsp;|&nbsp;
        <NavLink className={({isActive}) => isActive ? "text-[#179DAD] font-bold" : ""} to='/website-development'>websites</NavLink>&nbsp;|&nbsp;
        <NavLink className={({isActive}) => isActive ? "text-[#179DAD] font-bold" : ""} to='/app-development'>apps</NavLink>&nbsp;|&nbsp;
        <NavLink className={({isActive}) => isActive ? "text-[#179DAD] font-bold" : ""} to='/about-us'>about</NavLink>
      </div>
      <div className="hidden md:flex">
        <a href='mailto:contact@claervolker.com?subject=I have a message! ' className='btn-primary py-2'>Feeling old school? Email us directly.</a>
      </div>
    </div>
  );
}

export default Footer;
