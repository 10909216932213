import gsap from 'gsap/all';
import {  lazy, Suspense, useLayoutEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ServicePageLayout from './components/ServicePageLayout';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));

function App() {
  let el = useRef(null);
  let q = gsap.utils.selector(el);
  
  const services = [
    {
      name: 'Software Development',
      title: 'So, you require custom software development.',
      subtitle: 'We would like to facilitate the process of identifying your needs, analysing the outcomes and producing the code relevant to the project at hand.',
      steps: [
        {
          "name": "Identifying the project’s software needs",
          "description": "Software solutions come in all shapes and sizes. From low-code solutions with quick turnaround time, to fully functioning back-end and server side development. We place emphasis on identifying which route to take as this ultimately determines the price, deliverables and effectiveness of the solution",
          "title": "With that in mind, please allow us to gather some basic information around your needs:",
          "button": "next - outcomes",
          "questions": [
                    {type: 'text', label: 'In one sentence, describe your need', name: 'description'},
                    {type: 'text', label: 'Do you have a pre-determined budget in mind?', name: 'budget'},
                    {type: 'text', label: 'Is there a specific tech stack/stacks you need the project to be built in?', name: 'preferred_tech'},
                ],
        },
        {
          "name": "Identifying the project’s outcomes",
          "description": "In the modern world, there are many outcomes or processes of developing any software related products. While most of our clients have a clear idea in mind, there are some who would like some guidance around the possibilities",
          "title": "Determining the outcome of a project requires an in depth look at the bottom line impact, so:",
          "button": "next - deliverables",
          "questions": [
                    {type: 'text', label: 'What is the purpose of your project?', name: 'purpose'},
                    {type: 'text', label: 'Will the project be public facing or for internal use?', name: 'audience'},
                    {type: 'text', label: 'Do you have a timeline in mind for the project?', name: 'timeline'},
                ],
        },
        {
          "name": "Producing the desired deliverables of the project.",
          "description": "Software projects have a multitude of different components, front-end, back-end, server related etc. Effective software development needs to focus on and tie in with all of the above mentioned components.",
          "title": "Please provide some information around the ecosystem of the project:",
          "button": "next - contact details",
          "questions": [
                    {type: 'text', label: 'Is this a new or existing platform/environment or a rebuild?', name: 'status'},
                    {type: 'text', label: 'Does your project require front-end development or third-party integrations? (APIs, App, Dashboards etc.) ', name: 'integrations'},
                    {type: 'text', label: 'Do you require help with the setup of the environment for the project? (Back-End/Hosting)', name: 'hosting'},
                ],
        },
        {
          "name": "Gathering communication related details",
          "description": "We like to provide transparency on all our projects with continuous communication with out clients. This can be feedback related from a status point of the project or open communication with regards to suggestions and/or improvements to the project",
          "title": "Please provide contact details:",
          "button": "done - submit",
          "questions": [
                    {type: 'text', label: 'Company and/or personal name', name: 'name'},
                    {type: 'text', label: 'Email Address', name: 'email'},
                    {type: 'text', label: 'Cell/Telephone Number', name: 'contact'},
                ],
        },
      ],
    },
    {
      name: 'App Development',
      title: 'You have an idea for an app.',
      subtitle: 'Apps have been at the forefront of the software world for the better part of the last 15 years. The possibilities are endless.',
      steps: [
        {
        "name" : "Identifying the app's needs",
        "description" : "Apps can be built for various reasons and platforms. Identifying the core use for the app will determine whether we use the conventional route of Android/iOS based apps or a progressive web app (PWA)",
        "title" : "Please provide the basic details of your app:",
        "button" : "next - outcomes",
        "questions" : [
                {type: 'text', label: 'In one sentence, describe your app idea?', name: 'description'},
                {type: 'text', label: 'Do you have a pre-determined budget in mind?', name: 'budget'},
                {type: 'text', label: 'Is there specific platforms you would like to target with your app?', name: 'preferred_tech'},
              ]
        },
        {
          "name" : "Identifying the app's outcomes",
          "description" : "Apps are nothing more than wrappers for custom software solutions and thus can be built in various tech stacks. The functionality and integrations of the app will go hand in hand with the implementation and excecution of the app.",
          "title" : "An app should always affect your bottom line through either bringing in sales/users or enhancing productivity.efficiency:",
          "button" : "next - deliverables",
          "questions" : [
                    {type: 'text', label: 'What is the purpose of your app?', name: 'purpose'},
                    {type: 'text', label: 'Will the app be public facing or for internal use?', name: 'project_audience'},
                    {type: 'text', label: 'When would you like to launch the app?', name: 'timeline'},
                ]
        },
        {
          "name" : "Deploying the app you envisioned.",
          "description" : "App related projects tend to deploy in a different state than the client first had in mind. This can be due to UX/UI implications, tech shortfalls, design changes etc. We want to mitigate as many of these issues as possible.",
          "title" : "Please provide some information around the ecosystem of your app:",
          "button" : "next - contact details",
          "questions" : [
                    {type: 'text', label: 'Is this a new or existing app?', name: 'status'},
                    {type: 'text', label: 'Is your app a standalone app or are there integrations needed between your app and other platforms?', name: 'integrations'},
                    {type: 'text', label: 'Do you require help with the setup of the environment for the app? (Back-End/Hosting)', name: 'hosting'},
                ]
        },
        {
          "name" : "Gathering communication related details",
          "description" : "We like to provide transparency on all our projects with continuous communication with out clients. This can be feedback related from a status point of the project or open communication with regards to suggestions and/or improvements to the project",
          "title" : "Please provide contact details:",
          "button" : "done - submit",
          "questions" : [
                    {type: 'text', label: 'Company and/or personal name', name: 'name'},
                    {type: 'text', label: 'Email Address', name: 'email'},
                    {type: 'text', label: 'Cell/Telephone Number', name: 'contact'},
                ]
        },
      ]
    },
    {
      name: 'Website Development',
      title: 'A website represents you and/or your business online.',
      subtitle: 'We are experts at creating innovative online presences to captivate and engage your client base.',
      steps: [
      {
        name: "Identifying the website’s needs",
        description:
          "Websites can be overwhelming. Not only to potential customers but also to the responsible party of establishing the online presence. There are various factors to consider when looking to develop or refresh one’s online presence.",
        title:
          "Allow us to gather some information to conceptualise your new online presence:",
        button: "next - outcomes",
        questions: [
          {
            type: "text",
            label: "In one sentence, describe your brand/company?",
            name: "description",
          },
          {
            type: "text",
            label: "Do you have a pre-determined budget in mind?",
            name: "budget",
          },
          {
            type: "text",
            label: "Is the website an e-commerce or informational website?",
            name: "preferred_tech",
          },
        ],
      },
      {
        name: "Identifying the website's outcomes",
        description:
          "A website is a multipurpose tool enabling many business related functions. This can range from informational use, eCommerce, internal dashboards etc. A website’s end product is directly connected to the intended purpose.",
        title:
          "Determining the outcome of a website requires an in depth look at the bottom line impact, so:",
        button: "next - deliverables",
        questions: [
          {
            type: "text",
            label: "What is the purpose of your website?",
            name: "purpose",
          },
          {
            type: "text",
            label: "Will the project be public facing or for internal use?",
            name: "project_audience",
          },
          {
            type: "text",
            label: "Do you have a timeline in mind for the project?",
            name: "timeline",
          },
        ],
      },
      {
        name: "Producing the desired deliverables of the website.",
        description:
          "Websites can be developed and deployed in various ways. We are extremely skilled at interpreting the bottom-line concept of a website and delivering a solution either based on the client’s needs or on the suggestions we have to achieve the best results.",
        title:
          "Please provide some information around the ecosystem of the website:",
        button: "next - contact details",
        questions: [
          { type: "text", label: "Is this a new or existing website?", name: "status" },
          {
            type: "text",
            label:
              "Should your website integrate with any third-party software? (payment gateways, booking platforms etc.)",
            name: "integrations",
          },
          {
            type: "text",
            label:
              "Do you require help with the setup of the environment for the project? (Back-End/Hosting)",
            name: "hosting",
          },
        ],
      },
      {
        name: "Gathering communication related details",
        description:
          "We like to provide transparency on all our projects with continuous communication with out clients. This can be feedback related from a status point of the project or open communication with regards to suggestions and/or improvements to the project",
        title: "Please provide contact details:",
        button: "done - submit",
        questions: [
          { type: "text", label: "Company and/or personal name", name: "name" },
          { type: "text", label: "Email Address", name: "email" },
          { type: "text", label: "Cell/Telephone Number", name: "contact" },
        ],
      },
      ]
    }
  ]
  
  return (
    <>
      <div id='cursor' ref={el} className="hidden lg:block absolute z-10">
      </div>
      <Router>
        <Suspense fallback={<div className='preload'></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            {services.map((item, key) => 
              <Route key={item.name.toLowerCase().replace(/\s+/g, '-')} path={`/${item.name.toLowerCase().replace(/\s+/g, '-')}`} element={<ServicePageLayout key={item.name.toLowerCase().replace(/\s+/g, '-')} {...item}/>} />
            )}
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
