import gsap from 'gsap/all'
import { useLayoutEffect, useRef, useState } from 'react'
import {ReactComponent as StepNumber1} from '../images/1.svg'
import {ReactComponent as StepNumber2} from '../images/2.svg'
import {ReactComponent as StepNumber3} from '../images/3.svg'
import {ReactComponent as StepNumber4} from '../images/4.svg'

function StepForm(props){
    const el = useRef();
    const q = gsap.utils.selector(el);
    const [showForm, setShowForm] = useState(false)
    useLayoutEffect(()=>{
        gsap.from(q('div,p,svg,button'), {stagger: 0.1, opacity: 0})
        // eslint-disable-next-line
    },[props.active])
    
    return (
        <div className={`stepform items-center flex-col flex-1 justify-center gap-10 text-center w-4/5 mx-auto ${props.active === props.number ? 'flex' : 'hidden'}`} ref={el}>
        {props.number === 1 ? <StepNumber1 height={40}/> : null}
        {props.number === 2 ? <StepNumber2 height={40}/> : null}
        {props.number === 3 ? <StepNumber3 height={40}/> : null}
        {props.number === 4 ? <StepNumber4 height={40}/> : null}
        {!showForm ?
            <>
                <div className="w-[2px] h-[40px] bg-[#179dad] hidden md:block">&nbsp;</div>
                <p className="font-bold">{props.name}</p>
                <div className="w-[2px] h-[40px] bg-[#179dad] hidden md:block"></div>
                <p className="max-w-4xl">{props.description}</p>
                <div className="w-[2px] h-[40px] bg-[#179dad] hidden md:block"></div>
                <div className='flex flex-col'>
                    <button className="btn-primary no-anim" onClick={()=> {setShowForm(!showForm)}}>Fill the Form</button>
                    <button className="text-xs mt-5" onClick={props.action} back-button="true">{props.number > 1 ? "Go Back" : "Cancel"}</button>
                </div>
                
            </>
        :
            <div className="max-w-5xl">
                <form onSubmit={props.action} action="javascript:void(0);">
                    <p className="font-bold mb-5">{props.title}</p>
                    {props.questions.map((item,key)=> {
                        return(
                            <div key={item.name+key}>
                                <label htmlFor={item.name}>{item.label}</label>
                                <input type={item.type} required name={item.name} onChange={(e)=>{props.onchange({...props.answers, [item.name]: e.target.value})}} className="w-full block border-0 rounded-full bg-opacity-10 bg-black mb-10 mt-5"/>                
                            </div>
                        )
                    })}
                    <div className='flex flex-col'>
                        <button className="btn-primary no-anim" type='submit'>{props.button}</button>
                        <button className="text-xs mt-5" onClick={()=> {setShowForm(!showForm)}}>Go Back</button>&nbsp;&nbsp;
                    </div>
                </form>
            </div>
        }
    </div>   
    );
}

export default StepForm